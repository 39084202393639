<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <span @click="enableAddBtnCount += 1">Permissions</span>
            <v-spacer></v-spacer>
            <v-btn
              v-if="enableAddBtnCount >= 10 && enableAddBtnCount <= 13"
              outlined
              @click="form.dialog = true"
              ma-0
              small
              >New Permission
            </v-btn>
          </v-card-title>

          <alert-message
            title="Permissions"
            message="Name you permission in this patter [CREATE, READ, UPDATE, DELETE]"
          ></alert-message>
          <br />
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.name }} <br />
                  <small>{{ item.slug }}</small>
                </td>
                <td class="text-xs-right">
                  <edit-button
                    v-if="enableAddBtnCount >= 10 && enableAddBtnCount <= 13"
                    permission="permissions-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    v-if="enableAddBtnCount >= 10 && enableAddBtnCount <= 13"
                    permission="permissions-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-dialog v-model="form.dialog" persistent max-width="300px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add Permission</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      outlined
                      dense
                      label="Permission Name*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.name"
                      name="name"
                      :error-messages="form.errors.get('name')"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <small>*indicates required field</small>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              outlined
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" outlined @click="store">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import AlertMessage from "../../../../components/AlertMessage.vue";

export default {
  data: () => ({
    form: new Form(
      {
        name: "",
        module_id: "",
      },
      "/api/permissions"
    ),
    search: null,
    enableAddBtnCount: 0,
    pagination: {
      rowsPerPage: 10,
    },
    headers: [
      { text: "SN", align: "center", value: "id", width: 50, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Action",
        align: "right",
        sortable: false,
        width: 200,
        sortable: false,
      },
    ],
    services: [],
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    "form.name": function(value) {
      // console.log('test');
      this.form.name = value.toUpperCase();
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&") +
        "&moduleId=" +
        this.$route.params.moduleId
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        // this.pagination.totalItems = data.meta.total;
      });
    },

    store() {
      this.form.module_id = this.$route.params.moduleId;
      this.form.store();
    },
  },
};
</script>
<style lang="scss" scoped></style>
